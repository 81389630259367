<template>
  <div class="first-penguin">
    <div class="first-penguin__container">
      <div class="first-penguin__content">
        <div class="first-penguin__content--title">{{ data.title }}</div>
        <p class="first-penguin__content--sub mb-0">
          {{ data.welContent }}
        </p>

        <p v-if="flagReadMore1" class="first-penguin__content--sub">
          {{ data.sub }}
        </p>
      </div>
      <div v-if="flagReadMore1" class="first-penguin__block">
        <div class="first-penguin__message">
          <div><img v-lazy="messageIcon" alt="messIcon" /></div>
          <div class="first-penguin__message--detail">
            <p>{{ data.question }}</p>
          </div>
          <div style="align-self: flex-end">
            <img
              v-lazy="messageIcon"
              alt="messIcon"
              style="transform: rotate(-180deg)"
            />
          </div>
        </div>
        <div class="first-penguin__image d-none d-lg-block">
          <img v-lazy="firstPenguinImage" alt="firstPenguinImage" />
        </div>
      </div>
      <span
        class="d-block d-lg-none"
        @click="flagReadMore1 = !flagReadMore1"
        style="color: var(--orange); font-size: 16px; cursor: pointer"
        >{{ !flagReadMore1 ? btn.seeMore : btn.seeLess }}</span
      >
      <div class="first-penguin__image d-block d-lg-none">
        <img v-lazy="firstPenguinImage" alt="firstPenguinImage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'first-penguin',
  data() {
    return {
      messageIcon: '/images/icons/aboutus/message.svg',
      firstPenguinImage: '/images/people/first-penguin.png',
      data: this.$t('people').firstPenguin.data,
      btn: this.$t('people').firstPenguin.btn,
      poem: this.$t('people').firstPenguin.poem,
      imgPoem: ['/images/people/spot.png', '/images/people/spot-award.png'],
      flagReadMore1: false
    };
  },
  mounted() {
    this.poem = this.poem.map((item, indx) => ({
      ...item,
      img: this.imgPoem[indx]
    }));
    const windowWidth = window.screen.width;
    if (windowWidth >= 992) {
      this.flagReadMore1 = true;
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  watch: {
    lang() {
      this.data = this.$t('people').firstPenguin.data;
      this.btn = this.$t('people').firstPenguin.btn;
      this.poem = this.$t('people').firstPenguin.poem;
      this.poem = this.poem.map((item, indx) => ({
        ...item,
        img: this.imgPoem[indx]
      }));
    }
  }
};
</script>

<style lang="scss" scroped>
.first-penguin {
  width: 100%;
  position: relative;
  .first-penguin__container {
    overflow: hidden;
    width: 100%;
    margin-left: auto;
    padding: 200px 10%;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    background-color: #221e83;
  }
  .first-penguin__content {
    color: white;
    width: 44%;
  }
  .first-penguin__content--title {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 24px;
    position: relative;
  }
  .first-penguin__content--sub {
    font-weight: 400;
    font-size: 18px;
    color: #f5f5f5;
    margin-bottom: 10px;
    white-space: pre-line;
  }
  .first-penguin__block {
    width: 50%;
  }
  .first-penguin__message {
    display: flex;
    gap: 16px;
  }
  .first-penguin__message--detail {
    p {
      text-align: center;
      white-space: pre-line;
      font-weight: 400;
      font-size: 21px;
      color: white;
      margin-bottom: 0;
    }
  }
  .first-penguin__image {
    width: 100%;
    height: auto;
    margin-right: -104px;
    margin-top: -60px;
    margin-bottom: -111px;
  }

  .first-penguin__poem {
    margin: auto;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .first-penguin__poem__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4%;
  }
  .first-penguin__poem__item--content {
    width: 60%;
    white-space: pre-line;
    h3 {
      position: relative;
      padding-top: 16px;
      font-size: 21px;
      color: var(--blue-text);
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 10%;
        height: 4px;
        background-color: var(--orange);
      }
    }
    p {
      font-size: 18px;
      color: var(--grey-text);
    }
  }
  .first-penguin__poem__item--img {
    margin-top: 10px;
    width: 45%;
    & > img {
      width: 100%;
      max-width: 400px;
      object-fit: cover;
      border-radius: 50px;
    }
  }
  @media (max-width: 1024px) {
    .first-penguin__poem__item--img {
      margin: 20px auto 0px;
    }
    .first-penguin__poem {
      gap: 0px;
    }
    .first-penguin__container {
      flex-direction: column;
      gap: 20px;
    }
    .first-penguin__block,
    .first-penguin__content {
      width: 100%;
    }
    .first-penguin__poem__item {
      flex-direction: column-reverse;
      gap: 30px;
    }
    .first-penguin__poem__item--content {
      width: 100%;
    }
    .first-penguin__message {
      justify-content: center;
    }
  }
  @media (max-width: 992px) {
    .first-penguin__image {
      margin: 0;
      img {
        width: 100%;
      }
    }
    .first-penguin__container {
      margin-top: 0px;
      padding: 120px 10% 50px;
    }
  }
  @media (max-width: 768px) {
    .first-penguin__content--title {
      font-size: 32px;
    }
    .first-penguin__poem__item--img {
      width: 100%;
      margin-top: 10px;
    }
    .first-penguin__poem__item--content {
      width: 100%;
    }
  }
}
</style>
